@charset 'utf-8';

// Colors
$black: #000117;
$blue: #13a8fe;
$blue-complementary: #1396E6;
$red: #cf0060;
$orange: #ffa300;
$purple: #ff00ff;
$white-complementary: #F3FBFF;
$primary: $purple;

// Links
$link: $blue;
$link-hover: $orange;
$link-focus-border: $link-hover;

// Size
$body-size: 18px;

// Modal
$modal-content-width: 770px;

// Colors
$rss: #f26522;
$rss-invert: findColorInvert($rss);
$custom-colors: ("rss": ($rss, $rss-invert));
