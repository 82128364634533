div.highlight pre {
  padding: 0;
}
.content {
  div.highlight {
    text-align: left;
    margin-bottom: $size-6;
  }

  > pre {
    padding: 0;
  }

  a code { 
    text-decoration: underline; 
  }
  a:hover code {
    text-decoration: none;
  }

  li > div.highlighter-rouge {
    margin-top: $size-6;
  }
}

