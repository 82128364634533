.table-cell {
  &--check, &--cross, &--button {
    text-align: center;
  }
  &--check {
    color: $success;
  }
  &--cross {
    color: $danger;
  }
}

.features-table {
  .table-cell--header {
    text-align: center;
  }
}
