.logo-inline {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & svg {
    height: 1em;
    width: auto;

    & path {
      fill: currentColor !important;
    }
    & ellipse, & circle {
      stroke: currentColor !important;
    }
  }
}

.footer .logo-inline {
  vertical-align: middle;

  svg {
    width: $size-3;
    height: $size-3;
  }
}

.navbar-item-logo .logo {
  svg {
    width: 30px;
    height: 30px;
  }
}
.app-preview .logo {
  svg {
    width: 128px;
    height: 128px;
  }
}
