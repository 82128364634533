.button__light {
  display: none;
}
.button__dark {
  display: inline-block;
}

.producthunt {
  .light {
    display: inline;
  }
  .dark {
    display: none;
  }
}

@media (prefers-color-scheme: dark) {
  body, .footer {
    color: $light;
    background-color: $black;
  }
  p, strong, .title, .subtitle {
    color: $light;
  }
  .box, .notification {
    color: $light;
    background-color: $dark;
  }
  .content {
    h1, h2, h3, h4, h5, h6, label, table thead th {
      color: $light;
    }
  }

  @include from($tablet) {
    .navbar-end > .navbar-item {
      &, & > .navbar-link {
        color: $white;
      }
      &:not(div):hover, &.is-active {
        color: $white;
        border-bottom-color: $white;
      }
      & > .navbar-link {
        &:after {
          border-color: $white;
        }
      }
    }
  }

  .button__light {
    display: inline;
  }
  .button__dark {
    display: none;
  }

  .producthunt {
    .light {
      display: none;
    }
    .dark {
      display: inline;
    }
  }
}
