.app-preview {
  &-actions {
    margin-top: $size-6;
  }
}

.screenshot-macbook {
  text-align: center;

  @include from($tablet) {
    background-image: url('/assets/img/macook-half-wireframe-now-playing@2x.png');
    background-size: 466px 513px;
    background-position: 100% 0;
    background-repeat: no-repeat;

    text-align: right;
    min-height: 513px;

    &-image {
      margin: 23px 40px 0 0;
    }
  }
}

.action-buttons {
  margin-top: $size-1 * 2;
  & > div:not(:last-child) {
    margin-bottom: $size-1;
  }
}

.appstore-buttons {
  > *:not(:last-child) {
    margin: 0 1rem 0.5rem 0;
  }
}
.appstore-button {
  img {
    height: 60px;
  }
  &__small {
    img {
      height: 40px;
    }
  }
}
.setapp-button {
  display: inline-block;
}

.box-modal {
  .trigger {
    cursor: pointer;
  }
}

.page figure img { border-radius: 6px; }

.producthunt {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  text-align: center;
}
