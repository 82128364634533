fieldset { border: none; }

.has-shadow {
  box-shadow: $box-shadow;
}

.is-vertical-center {
  display: flex;
  align-items: center;
}

.post-header,
.page-header {
  margin-bottom: $size-1;
}

.page {
  &-description {
    font-family: Neuton, serif;
    &:before {
      color: $white-bis;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.15em;
      vertical-align: -0.4em;
    }
  }
}
