.navbar {
  &.is-transparent {
    background-color: transparent;
    background-image: none;
  }

  &-dropdown {
    min-width: 15rem;
  }
}

.dropdown-item {
  &__app {
    display: grid;
    grid-template-columns: 48px 1fr;
  }
}

@include from($tablet) {
  .navbar-end > .navbar-item {
    &, & > .navbar-link {
      color: $black;
      border-bottom: transparent 1px solid;
    }
    &:not(div):hover, &.is-active {
      color: $black;
      border-bottom-color: $black;
    }
    & > .navbar-link {
      &:after {
        border-color: $black;
      }
    }
  }
}
