.title,
.logo {
  font-family: $family-title;
}

.title {
  &.is-1 {
    font-family: "Oswald", $family-title;
  }

  sup {
    position: absolute;
    margin: -1rem 0.5rem;

    font-family: $family-primary;
  }
}

.has-text-white {
  .title, .subtitle {
    color: $white;
  }
}

.content {
  blockquote {
    background: transparent;
  }
}

.page-description {
  color: $black;
}
