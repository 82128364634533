.post-list {
  & .post-header {
    margin-bottom: $size-3;
  }
  & .post {
    margin-bottom: $size-1;
    padding-bottom: $size-1;
    border-bottom: $grey-lighter 1px solid;
  }
}
.archive {
  & .post:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}
.post {
  article.content {
    margin-bottom: $size-1;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .setapp-button {
      margin: $size-1 0;
    }
  }
}
